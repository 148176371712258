// @ts-nocheck
import { Router } from '@gatsbyjs/reach-router';
import React from 'react';

import MergeDocument from '../components/MergeDocument/MergeDocument';
import AppLayout from '../components/layout/AppLayout';

const Compare = () => {
  return (
    <AppLayout>
      <Router>
        <MergeDocument path="/document-merge" />
      </Router>
    </AppLayout>
  );
};
export default Compare;
