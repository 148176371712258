import { useLocation } from '@gatsbyjs/reach-router';
import { Box } from '@mui/material';
import React, { useRef, useState } from 'react';
import { pdfjs } from 'react-pdf';

import { useGetDocumentMerge } from '../../services/queries';
import { DocumentMerge, DocumentNode } from '../../types/RealtaDocument';
import DocumentView from './DocumentView';
import MergeView from './MergeView';
import TOC from './TOC';
import { HEIGHT } from './type';
import { inchToPx } from './utils';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const offset = 80;

const MergeDocument: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sourceDocUuid = queryParams.get('source');
  const targetDocUuid = queryParams.get('target');
  const pdfUrlSource = `https://ingestion-analysis-process-doc-bucket.s3.ap-southeast-2.amazonaws.com/${sourceDocUuid}/source.pdf`;
  const pdfUrlTarget = `https://ingestion-analysis-process-doc-bucket.s3.ap-southeast-2.amazonaws.com/${targetDocUuid}/source.pdf`;

  const originalDocRef = useRef<HTMLDivElement>(null);
  const updatedDocRef = useRef<HTMLDivElement>(null);
  const [focusMergeChange, setFocusMergeChange] = useState<DocumentMerge | null>(null);
  const { data: documentMerge, isLoading, refetch } = useGetDocumentMerge(sourceDocUuid, targetDocUuid);

  const handleMergeClick = (merge: DocumentMerge) => {
    setFocusMergeChange(merge);
    if (
      !merge.src?.endBoundryPage ||
      !merge.src?.startBoundryPage ||
      !merge.src?.endBoundryY ||
      !merge.src?.startBoundryY
    ) {
      return;
    }
    const { startBoundryPage, startBoundryY } = merge.src;
    if (originalDocRef.current) {
      const calculatedStartBoundryPage = startBoundryPage > 1 ? startBoundryPage - 1 : 0;
      const top = HEIGHT * calculatedStartBoundryPage + inchToPx(startBoundryY);
      originalDocRef.current.scrollTo({ top: top - offset, behavior: 'smooth' });
    }
    if (updatedDocRef.current) {
      const calculatedStartBoundryPage = startBoundryPage > 1 ? startBoundryPage - 1 : 0;
      const top = HEIGHT * calculatedStartBoundryPage + inchToPx(startBoundryY);
      updatedDocRef.current.scrollTo({ top: top - offset, behavior: 'instant' });
    }
  };

  const handleNodeClick = (node: DocumentNode) => {
    if (originalDocRef.current && updatedDocRef.current && node.startBoundryPage && node.startBoundryY) {
      const startBoundryPage = node.startBoundryPage && node.startBoundryPage > 1 ? node.startBoundryPage - 1 : 0;
      const top = HEIGHT * startBoundryPage + inchToPx(node.startBoundryY);

      originalDocRef.current.scrollTo({ top: top - offset, behavior: 'smooth' });
      updatedDocRef.current.scrollTo({ top: top - offset, behavior: 'smooth' });
    }
  };

  const handleFocusMergeChange = (merge: DocumentMerge, isRoot?: boolean) => {
    setFocusMergeChange(merge);
    if (
      !merge.src?.endBoundryPage ||
      !merge.src?.startBoundryPage ||
      !merge.src?.endBoundryY ||
      !merge.src?.startBoundryY
    ) {
      return;
    }
    const { startBoundryPage, startBoundryY } = merge.src;
    if (isRoot) {
      if (updatedDocRef.current) {
        const calculatedStartBoundryPage = startBoundryPage > 1 ? startBoundryPage - 1 : 0;
        const top = HEIGHT * calculatedStartBoundryPage + inchToPx(startBoundryY);
        updatedDocRef.current.scrollTo({ top: top - offset, behavior: 'smooth' });
      }
    } else {
      if (originalDocRef.current) {
        const calculatedStartBoundryPage = startBoundryPage > 1 ? startBoundryPage - 1 : 0;
        const top = HEIGHT * calculatedStartBoundryPage + inchToPx(startBoundryY);
        originalDocRef.current.scrollTo({ top: top - offset, behavior: 'smooth' });
      }
    }
  };

  if (!sourceDocUuid || !targetDocUuid) {
    return <Box>You need to add 2 params: source and target as 2 document ids in url to merge document</Box>;
  }

  return (
    <Box>
      {isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="fixed"
          top={0}
          left={0}
          width="100vw"
          height="100vh"
          bgcolor="black"
          color="white"
          zIndex={10}
        >
          <h2>Merging documents...</h2>
        </Box>
      )}
      <TOC documentUuid={sourceDocUuid} onClick={handleNodeClick} />
      <Box display="flex" gap={1}>
        <Box sx={{ display: 'flex', gap: 2, flexShrink: 0 }}>
          <Box height="calc(100vh - 80px)" sx={{ overflowY: 'auto' }} ref={originalDocRef}>
            <DocumentView
              url={pdfUrlSource}
              mergeMap={documentMerge?.map}
              focusMergeChange={focusMergeChange}
              onMergeChangeClick={handleFocusMergeChange}
            />
          </Box>
          <Box height="calc(100vh - 80px)" sx={{ overflowY: 'auto' }} ref={updatedDocRef}>
            <DocumentView
              url={pdfUrlTarget}
              mergeMap={documentMerge?.map}
              isRoot={false}
              focusMergeChange={focusMergeChange}
              onMergeChangeClick={handleFocusMergeChange}
            />
          </Box>
        </Box>
        <Box height="calc(100vh - 80px)" sx={{ overflowY: 'auto' }}>
          <MergeView
            merges={documentMerge?.list || []}
            focusMerge={focusMergeChange}
            onMergeClick={handleMergeClick}
            onSolve={async () => {
              await refetch();
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default MergeDocument;
